export const useAuthenticationSignIn = () => {
  const client = useDatabaseClient();

  const signInWithGoogle = async () => {
    await client.auth.signInWithOAuth({
      provider: "google",
      options: {
        redirectTo: window.location.origin + "/auth/callback",
      },
    });
  };

  const signOut = async () => {
    await client.auth.signOut({ scope: "local" });

    return navigateTo("/auth/login");
  };

  return { signInWithGoogle, signOut };
};
